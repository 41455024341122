import { useTranslation } from "react-i18next";

import { Button } from "@aviary";
// eslint-disable-next-line @fullscript/cross-reference
import { practitionerRoutes } from "@practitioner/data/practitionerRoutes";
import FullscriptLogoGreenLeafPigeonTextSVGComponent from "@shared/assets/logo/FullscriptLogoGreenLeafPigeonText";
import { useBreakpoints, useCopyToClipboard } from "@shared/hooks";
import { useLocation } from "@shared/react-router-dom";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./StoreLandingPageNavBar.styles";

interface Props {
  isPreview?: boolean;
  isStoreOwner?: boolean;
  isShowOnDesktop?: boolean;
}

const StoreLandingPageNavBar = ({ isPreview, isStoreOwner, isShowOnDesktop }: Props) => {
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();
  const { pathname } = useLocation();
  const currentPath = `${location.origin}${pathname}`;
  const copyToClipboard = useCopyToClipboard();

  const copyEmbedCode = async () => {
    await copyToClipboard(currentPath, {
      successMessage: t(l.patientRxAuth.storeLandingPages.toastCopySuccessMessage),
    });
  };

  const fsLogo = (
    <FullscriptLogoGreenLeafPigeonTextSVGComponent
      css={styles.svgContainer}
      data-testid="Fullscript logo"
    />
  );

  const previewPageNav = (
    <div css={styles.navBar} data-testid="storeSignupPreviewNav">
      {tablet.lessThan && fsLogo}
      <div css={styles.buttonsContainer}>
        {isStoreOwner && (
          <Button
            href={practitionerRoutes.storeSettings.landingPage}
            css={styles.button}
            size="small"
          >
            {t(l.patientRxAuth.storeLandingPages.backToEdit)}
          </Button>
        )}
        <Button isColor="system" css={styles.button} onClick={copyEmbedCode} size="small">
          {tablet.lessThan
            ? t(l.patientRxAuth.storeLandingPages.copyLink)
            : t(l.patientRxAuth.storeLandingPages.copyStoreLink)}
        </Button>
      </div>
    </div>
  );

  const pageNav = <div css={styles.navBar}>{fsLogo}</div>;

  return (
    <>
      {isPreview && previewPageNav}
      {!isPreview && (tablet.lessThan || isShowOnDesktop) && pageNav}
    </>
  );
};

export { StoreLandingPageNavBar };
