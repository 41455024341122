import { useTranslation } from "react-i18next";

import { Button, Typography } from "@aviary";
import { Container } from "@aviary/layouts";
import { AviaryProvider } from "@aviary/utils";
import { SvgFullscriptIcon } from "@shared/assets/logo";
import type { To } from "@shared/react-router-dom";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./Fallback.styles";

interface Props {
  to?: To;
}

const Fallback = ({ to }: Props) => {
  const { t } = useTranslation();

  const renderBackToHome = () => {
    if (!to) return null;

    return (
      <Button to={to} isColor="primary">
        {t(l.error.BackToHome)}
      </Button>
    );
  };

  return (
    <AviaryProvider>
      <Container css={styles.errorBoundary}>
        <div css={styles.content}>
          <Typography css={styles.title} type="h1">
            {t(l.error.Oops)}
          </Typography>
          <div css={styles.subTitleContainer}>
            <SvgFullscriptIcon css={styles.logo} />
            <Typography css={styles.subTitle} type="h5">
              {t(l.error.ApplicationErrorText)}
            </Typography>
          </div>
          <p css={styles.text}>{t(l.error.ApplicationErrorMessage)}</p>
          {renderBackToHome()}
        </div>
      </Container>
    </AviaryProvider>
  );
};

export { Fallback };
