import { css, type Theme } from "@emotion/react";

import { dimensions } from "@styles/index";

export const footer = (theme: Theme) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0 1.5rem;
  border-top: 1px solid ${theme.system.borderBase};

  @media (max-width: ${dimensions.phoneLargeMax}) {
    flex-wrap: wrap;
    margin-top: 0;
  }
`;

export const privacyPolicy = css`
  text-align: center;
  padding: 1rem 0.5rem;
`;

export const footerLogos = css`
  margin-left: 2rem;
`;
