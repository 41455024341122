import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const title = css`
  text-align: center;

  @media (max-width: ${dimensions.phoneLargeMax}) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;
