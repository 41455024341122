import { FooterLogos, FooterPrivacy } from "@shared/components";
import { useBreakpoints } from "@shared/hooks";

import { footer, footerLogos, privacyPolicy } from "./StoreLandingFooter.styles";

const StoreLandingFooter = () => {
  const { phoneLarge } = useBreakpoints();

  return (
    <div css={footer}>
      <div css={phoneLarge.greaterThan && footerLogos}>
        <FooterLogos />
      </div>
      <FooterPrivacy css={privacyPolicy} />
    </div>
  );
};

export { StoreLandingFooter };
