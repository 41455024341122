import type { ButtonProps } from "@aviary/components/Button/Button";
import { useDropdownContext } from "@aviary/components/Dropdown/DropdownContext";

const useDropdownButtonProps = (): Partial<ButtonProps> => {
  const {
    onTriggerClicked,
    setMouseOverTrigger,
    setTriggerFocused,
    isDisabled,
    isFullWidth,
    setTriggerElement,
  } = useDropdownContext();

  const onClick = e => {
    onTriggerClicked();
    if (e) setTriggerElement(e.currentTarget);
  };

  const onMouseEnter = e => {
    setMouseOverTrigger(true);
    if (e) setTriggerElement(e.currentTarget);
  };

  const onMouseLeave = () => {
    setMouseOverTrigger(false);
  };

  const onFocus = () => {
    setTriggerFocused(true);
  };

  const onBlur = () => {
    setTriggerFocused(false);
  };

  return {
    onClick,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    disabled: isDisabled,
    isFullWidth,
  };
};

export { useDropdownButtonProps };
