import { SignInLayout } from "@unauthenticated/signupSignin/components";
import { ForgotPasswordContent } from "@unauthenticated/signupSignin/components/ForgotPasswordContent";

const ForgotPasswordPage = () => {
  return (
    <SignInLayout>
      <ForgotPasswordContent />
    </SignInLayout>
  );
};

export { ForgotPasswordPage };
