import { css } from "@emotion/react";

import { dimensions } from "@styles";

export const logo = css`
  max-width: 15rem;
  margin-bottom: 1rem;

  @media (max-width: ${dimensions.phoneMax}) {
    max-width: 7.625rem;
  }

  @media (max-width: ${dimensions.phoneSmallMax}) {
    display: none;
  }
`;
